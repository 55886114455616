import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../Components/Layout';
import ProjectTile from '../Components/ProjectTile';
import MasonryLayout from '../Components/MasonryLayout';

const code = ({ data }) => {

  const codeList = data.allMdx.edges.map(codeProject => {
    return (
      <ProjectTile 
        link={codeProject.node.slug}
        title={codeProject.node.frontmatter.title} 
        tileImage={codeProject.node.frontmatter.tileImage}
        alt={codeProject.node.frontmatter.tileAlt}
        id={codeProject.node.id}
        key={codeProject.node.id}
      />
    )
  })

  return (
    <Layout>
      <MasonryLayout content={codeList} />
      <article className='project-page'>
        <a 
          href="https://github.com/mattumland"
          className="project-page-link" 
          target={"_blank"}
          rel="noopener noreferrer"
        >See more on Github</a>
      </article>
    </Layout>
  );
}

export default code; 

export const data = graphql` {
  allMdx(
    sort: {fields: [frontmatter___order], order: DESC}
    filter: {frontmatter: {tags: {eq: "code"}}}
  ) {
    edges {
      node {
        slug
        id
        frontmatter {
          title
          tileAlt
          tileImage {
            childImageSharp {
              gatsbyImageData(width: 1000, placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
}`
